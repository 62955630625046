import LanguageIcon from '@mui/icons-material/Language';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useIntl } from 'react-intl';
import { useIntlContext } from '../contextProviders/useIntlContext';
import { ISO6391LanguageCode } from '../utils/locale/types';
interface LanguageOption {
    code: ISO6391LanguageCode;
    label: string;
    countryCode: string;
}

const LANGUAGES: LanguageOption[] = [
    {
        code: ISO6391LanguageCode.en,
        label: 'English',
        countryCode: 'US',
    },
    {
        code: ISO6391LanguageCode.ar,
        label: 'العربية',
        countryCode: 'SA',
    },
    {
        code: ISO6391LanguageCode.bg,
        label: 'Български',
        countryCode: 'BG',
    },
    {
        code: ISO6391LanguageCode.hr,
        label: 'Hrvatski',
        countryCode: 'HR',
    },
    {
        code: ISO6391LanguageCode.cs,
        label: 'Čeština',
        countryCode: 'CZ',
    },
    {
        code: ISO6391LanguageCode.fr,
        label: 'Français',
        countryCode: 'FR',
    },
    {
        code: ISO6391LanguageCode.de,
        label: 'Deutsch',
        countryCode: 'DE',
    },
    {
        code: ISO6391LanguageCode.he,
        label: 'עברית',
        countryCode: 'IL',
    },
    {
        code: ISO6391LanguageCode.hu,
        label: 'Magyar',
        countryCode: 'HU',
    },
    {
        code: ISO6391LanguageCode.nb,
        label: 'Bokmål',
        countryCode: 'NO',
    },
    {
        code: ISO6391LanguageCode.pl,
        label: 'Polski',
        countryCode: 'PL',
    },
    {
        code: ISO6391LanguageCode.pt,
        label: 'Português',
        countryCode: 'PT',
    },
    {
        code: ISO6391LanguageCode.ro,
        label: 'Română',
        countryCode: 'RO',
    },
    {
        code: ISO6391LanguageCode.ru,
        label: 'Русский',
        countryCode: 'RU',
    },
    {
        code: ISO6391LanguageCode.sk,
        label: 'Slovenčina',
        countryCode: 'SK',
    },
    {
        code: ISO6391LanguageCode.es,
        label: 'Español',
        countryCode: 'ES',
    },
    {
        code: ISO6391LanguageCode.tr,
        label: 'Türkçe',
        countryCode: 'TR',
    },
    {
        code: ISO6391LanguageCode.uk,
        label: 'Українська',
        countryCode: 'UA',
    },
];

export const LanguageSelector: React.FC = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { setLocale } = useIntlContext();
    const intl = useIntl();

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLanguageChange = (lang: ISO6391LanguageCode) => {
        setLocale(lang);
        handleClose();
    };

    return (
        <>
            <Tooltip title={intl.formatMessage({ id: 'change_language' })}>
                <IconButton onClick={handleOpen} sx={{ p: 1 }}>
                    <LanguageIcon />
                </IconButton>
            </Tooltip>
            <Menu
                sx={{ mt: '45px' }}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {LANGUAGES.map((lang) => (
                    <MenuItem
                        key={lang.code}
                        onClick={() => handleLanguageChange(lang.code)}
                        sx={{
                            minWidth: '150px',
                            display: 'flex',
                            gap: 2,
                            alignItems: 'center',
                        }}
                    >
                        <ReactCountryFlag
                            countryCode={lang.countryCode}
                            svg
                            style={{
                                width: '24px',
                                height: '16px',
                            }}
                        />
                        {lang.label}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};
